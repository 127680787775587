/* You can add global styles to this file, and also import other style files */
@use "@angular/material" as mat;
@include mat.core();
@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body {
  height: 100%;
  background-color: #fff;
}

// body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }

:root {
  --primary-light: #da716f;
  --primary-default: #c4312b;
  --primary-dark: #a8211a;
  --primary-disabled: #d56c6baa;

  --secondary-light: #818e97;
  --secondary-default: #52616d;
  --secondary-dark: #2b3846;

  --warn-light: #fff170;
  --warn-default: #ffab1f;
  --warn-dark: #e48000;

  --success-light: #44f5a5;
  --success-default: #05cc62;
  --success-dark: #048c1f;

  --error-light: #eb6262;
  --error-default: #ee3d3d;
  --error-dark: #f20505;

  --white: #ffffff;
  --black: #000000;
  --disabled: rgba(113, 103, 103, 0.604);
  --readonly: #E5E4E2;
}

::-webkit-scrollbar {
  width: 8px;
  height: 8px;
  background-color: #f5f5f5;
}

::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.6);
  background-color: #cccccc;
  //background-color: #b7adad;
}

::-webkit-scrollbar-thumb {
  border-radius: 50px;
  background-color: var(--primary-dark);
  background-image: -webkit-linear-gradient(90deg,
      var(--primary-dark),
      var(--primary-light),
      transparent 100%,
      var(--primary-dark),
      transparent);
}

::-webkit-scrollbar-thumb:hover {
  background: var(--primary-default);
}

::-webkit-scrollbar-button:single-button {
  background-color: transparent;
  display: block;
  background-size: 10px;
  background-repeat: no-repeat;
}

/* Up */
::-webkit-scrollbar-button:single-button:vertical:decrement {
  height: 12px;
  width: 16px;
  background-position: center 4px;
  background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='100' height='100' fill='rgb(0,0,0)'><polygon points='50,00 0,50 100,50'/></svg>");
}

::-webkit-scrollbar-button:single-button:vertical:decrement:hover {
  background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='100' height='100' fill='rgb(112, 112, 112)'><polygon points='50,00 0,50 100,50'/></svg>");
}

::-webkit-scrollbar-button:single-button:vertical:decrement:active {
  background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='100' height='100' fill='rgb(128, 128, 128)'><polygon points='50,00 0,50 100,50'/></svg>");
}

/* Down */
::-webkit-scrollbar-button:single-button:vertical:increment {
  height: 12px;
  width: 16px;
  background-position: center 2px;
  background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='100' height='100' fill='rgb(0,0,0)'><polygon points='0,0 100,0 50,50'/></svg>");
}

::-webkit-scrollbar-button:single-button:vertical:increment:hover {
  background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='100' height='100' fill='rgb(112, 112, 112)'><polygon points='0,0 100,0 50,50'/></svg>");
}

::-webkit-scrollbar-button:single-button:vertical:increment:active {
  background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='100' height='100' fill='rgb(128, 128, 128)'><polygon points='0,0 100,0 50,50'/></svg>");
}

/* Left */
::-webkit-scrollbar-button:single-button:horizontal:decrement {
  height: 12px;
  width: 16px;
  background-position: center;
  background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='100' height='100' fill='rgb(0,0,0)'><polygon points='0,50 50,100 50,0'/></svg>");
}

::-webkit-scrollbar-button:single-button:horizontal:decrement:hover {
  background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='100' height='100' fill='rgb(112, 112, 112)'><polygon points='0,50 50,100 50,0'/></svg>");
}

::-webkit-scrollbar-button:single-button:horizontal:decrement:active {
  background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='100' height='100' fill='rgb(128, 128, 128)'><polygon points='0,50 50,100 50,0'/></svg>");
}

/* Right */
::-webkit-scrollbar-button:single-button:horizontal:increment {
  height: 12px;
  width: 16px;
  background-position: center;
  background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='100' height='100' fill='rgb(0,0,0)'><polygon points='0,0 0,100 50,50'/></svg>");
}

::-webkit-scrollbar-button:single-button:horizontal:increment:hover {
  background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='100' height='100' fill='rgb(112, 112, 112)'><polygon points='0,0 0,100 50,50'/></svg>");
}

::-webkit-scrollbar-button:single-button:horizontal:increment:active {
  background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='100' height='100' fill='rgb(128, 128, 128)'><polygon points='0,0 0,100 50,50'/></svg>");
}

html,
body {
  height: 100%;
  overflow: auto;
}

body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: small;
  overflow: auto;
}

//ngx-data-table
@import "./../node_modules/@swimlane/ngx-datatable/index.scss";
@import "./../node_modules/@swimlane/ngx-datatable/themes/material.scss";

.ngx-datatable.material.single-selection .datatable-body-row.active:hover,
.ngx-datatable.material.single-selection .datatable-body-row.active:hover .datatable-row-group,
.ngx-datatable.material.multi-selection .datatable-body-row.active:hover,
.ngx-datatable.material.multi-selection .datatable-body-row.active:hover .datatable-row-group,
.ngx-datatable.material.multi-click-selection .datatable-body-row.active:hover,
.ngx-datatable.material.multi-click-selection .datatable-body-row.active:hover .datatable-row-group {
  background-color: var(--secondary-light);
}

.ngx-datatable.material.single-selection .datatable-body-row.active .datatable-body-cell {
  color: var(--white);
}

.ngx-datatable.material.single-selection .datatable-body-row.active,
.ngx-datatable.material.single-selection .datatable-body-row.active .datatable-row-group,
.ngx-datatable.material.multi-selection .datatable-body-row.active,
.ngx-datatable.material.multi-selection .datatable-body-row.active .datatable-row-group,
.ngx-datatable.material.multi-click-selection .datatable-body-row.active,
.ngx-datatable.material.multi-click-selection .datatable-body-row.active .datatable-row-group {
  background-color: var(--secondary-light);
}

.ngx-datatable .datatable-body .datatable-row-wrapper {
  border-bottom-width: 0.5px;
}

.ngx-datatable {
  border-bottom-left-radius: 0.375rem;
  border-bottom-right-radius: 0.375rem;
}

.ngx-datatable.material .datatable-body .datatable-body-row .datatable-body-cell {
  //padding: 0.5rem 1.2rem;
  padding: 0.1rem 1.2rem;
}

ngx-datatable.material .datatable-header .datatable-header-cell {
  padding: 0.1rem 1.2rem !important;
}

.ngx-datatable.material .datatable-header {
  height: auto !important;
  background-color: var(--primary-dark);
}

.ngx-datatable.fixed-header .datatable-header .datatable-header-inner .datatable-header-cell {
  color: var(--white);
  font-size: 12px;
  background-color: var(--primary-dark);
  border-right-color: var(--primary-light);
  border-style: solid;
  border-right-width: 0.05px;
}

.ngx-datatable.material .datatable-body .datatable-body-row .datatable-body-cell {
  font-size: 12px;
}

.ngx-datatable {
  .sortable .sort-btn:before {
    font-family: data-table;
    content: "\21F5";
  }

  .sortable .sort-btn.datatable-icon-down:before {
    content: "\2193";
  }

  .sortable .sort-btn.datatable-icon-up:before {
    content: "\2191";
  }
}

.ngx-datatable.material .datatable-footer .datatable-pager .datatable-icon-prev:before {
  content: "<<";
  font-size: small;
}

.ngx-datatable.material .datatable-footer .datatable-pager .datatable-icon-left:before {
  content: "<";
  font-size: medium;
}

.ngx-datatable.material .datatable-footer .datatable-pager .datatable-icon-right:after {
  content: ">";
  font-size: medium;
}

.ngx-datatable.material .datatable-footer .datatable-pager .datatable-icon-skip:after {
  content: ">>";
  font-size: small;
}

.ngx-datatable .datatable-header .datatable-header-cell .sort-btn {
  float: right;
  padding-top: 2.5px;
}

// .ngx-datatable .datatable-body-row > div {
//   transform: translate3d(0, 0px, 0px) !important;
// }

.ngx-datatable .datatable-footer-inner {
  overflow-y: hidden;
}

.ngx-datatable .datatable-header .datatable-header-cell .datatable-header-cell-template-wrap {
  display: flex;
  justify-content: space-between;
}

.page-header {
  background-color: var(--secondary-dark);
}
.mat-mdc-outlined-button:not(:disabled) {
  color: #fff !important;
  font-size: 0.85rem;
  width: 160px !important;
}
.mat-mdc-outlined-button[disabled][disabled] {
  width: 160px !important;
  font-size: 0.85rem;
  padding: 18px;
  --mdc-protected-button-disabled-container-color:#d56c6baa !important;
  --mdc-protected-button-disabled-label-text-color: rgba(113, 103, 103, 0.604) !important;
}
.mat-mdc-form-field-flex .mat-mdc-form-field-infix .mat-mdc-select .mat-mdc-select-trigger .mat-mdc-select-value .mat-mdc-select-value-text {
  font-size: 0.85rem;
}